import React, { Component } from "react";
import "./App.css";
import { RedocStandalone } from "redoc";

class App extends Component {
  render() {
    return <RedocStandalone specUrl="/doc/openapi/index.yaml" />;
  }
}

export default App;
